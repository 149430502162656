import React, { useEffect, useState } from "react";
import "./Billing.css";
import Checkout from "./Checkout";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { db, auth, child, push } from "./firebase";
import { onValue, ref, set, update } from "firebase/database";
import axios from "axios";
import { ExcelExport } from "./Excel/ExcelExport";
import { ExportCSV } from "./Excel/ExportToCSV";
import { useSpeechSynthesis } from "react-speech-kit";
// import * as fs from "fs";
import { Document, Packer, Paragraph, TextRun } from "docx";
import Download from "@axetroy/react-download";
// import MDocument from './MDocument';
import * as docx from "docx";
import { jwtDecode } from "jwt-decode";
import useSound from "use-sound";
import { v4 as uuidv4 } from "uuid";
import boopSfx from "./chat.mp3";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const stripePromise = loadStripe(
  "pk_test_51LnMTTH7bYn4KIwLbgZZFQww66bsnJn8fZJMnCh8BNUs7VZWQ3Sj2IoPgOrSTEBBgT7yYKpahzAJyBIEljRdIMpX002iZTvKiC"
);
const Profile = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [website, setWebsite] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [show, setShow] = useState("none");

  const [projects, setProjects] = useState([]);
  // const { speak } = useSpeechSynthesis();
  const [clientSecret, setClientSecret] = useState("");
  var itemstofilter = [];

  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");

  const [uid, setUid] = useState("");
  const [msg, setMsg] = useState([]);
  const [data, setData] = useState([]);
  const [credit, setCredit] = useState("");
  const [creditBalance, setCreditBalance] = useState("");
  const [creditUsed, setCreditUsed] = useState("");

  const onEnd = () => {
    //setHighlightedText('')
  };

  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
    onEnd,
  });

  useEffect(() => {}, []);
  const validatePassword = () => {
    const minLengthError = validateMinLength(resetPassword);
    const containsLowercaseError = validateContainsLowercase(resetPassword);
    const containsUppercaseError = validateContainsUppercase(resetPassword);
    const containsNumberOrSymbolError =
      validateContainsNumberOrSymbol(resetPassword);

    if (minLengthError) {
      toast.error(minLengthError);
      return false;
    }
    if (containsLowercaseError) {
      toast.error(containsLowercaseError);
      return false;
    }
    if (containsUppercaseError) {
      toast.error(containsUppercaseError);
      return false;
    }
    if (containsNumberOrSymbolError) {
      toast.error(containsNumberOrSymbolError);
      return false;
    }
    return true;
  };

  const validateMinLength = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long";
    }
    return "";
  };

  const validateContainsLowercase = (password) => {
    if (!/[a-z]/.test(password)) {
      return "Password must contain at least one lowercase letter";
    }
    return "";
  };

  const validateContainsUppercase = (password) => {
    if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one uppercase letter";
    }
    return "";
  };

  const validateContainsNumberOrSymbol = (password) => {
    if (!/[\d!@#$%^&*()_+]/.test(password)) {
      return "Password must contain at least one number or special character";
    }
    return "";
  };

  const [play] = useSound(boopSfx);
  const handleSubmit = (e) => {
    e.preventDefault();
    var spomp = `explain for 30 year old person ${prompt}`;

    setShow("block");
    // Send a request to the server with the prompt
    axios
      .post("http://chatbaddybe.rbdemo.live/chat", { prompt })
      .then((res) => {
        // Update the response state with the server's response
        setResponse(res.data.choices[0].text);
        play();

        //speak({ text: res.data.choices[0].text });
        setMsg([...msg, { req: prompt, res: res.data.choices[0].text }]);
        setShow("none");
        let uuid = uuidv4();
        const d = new Date();
        update(ref(db, "users/" + uid + "/searches/" + uuid), {
          credit: res.data.usage.completion_tokens,
          description: prompt,
          datetime: d,
        });

        //    update(ref(db, 'users/'+uid+'/credit/'), {
        //     credit-
        //            });
      })
      .catch((err) => {
        console.error(err);
        setShow("none");
      });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      fetchUserData(decodedToken.userId);
    } else {
      navigate("/profile");
    }
  }, [navigate]);

  const fetchUserData = async (userId) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    try {
      const response = await axios.get(`${baseURL}/user/${userId}`);
      const userData = response.data.user;
      setEmail(userData.email);
      setCompanyName(userData.company_name);
      setUserFullName(userData.user_full_name);
      setAddress(userData.address);
      setCountry(userData.country);
      setWebsite(userData.website);
      setContact(userData.contact_number);
      //setResetPassword(userData.password);
      setUid(userData.id);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Function to handle form submission
  const SaveChanges = async (e) => {
    e.preventDefault();
    const baseURL = process.env.REACT_APP_BASE_URL;

    // Validate password
    const isPasswordValid = validatePassword();
    if (!isPasswordValid) {
      return;
    }

    try {
      const response = await axios.put(`${baseURL}/profile-update/${uid}`, {
        // Pass user input data to the backend
        company_name: companyName,
        user_full_name: userFullName,
        address: address,
        country: country,
        email: email,
        website: website,
        contact_number: contact,
        password: resetPassword, // Include password if needed
      });
      console.log(response.data); // Log success message or response from backend
      // Display a success toast notification
      toast.success("Profile updated successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      // Display an error toast notification
      toast.error("Failed to update profile. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // create a payment intent
  useEffect(() => {
    setProjects([]);

    const query = ref(db, "users");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          // console.log(project);
          if (project.email === email) {
            setProjects((projects) => [...projects, project]);

            setCredit(project.credit);
            setCreditBalance(project.creditBalance);
            setCreditUsed(project.creditUsed);
          }
        });
      }
    });

    //   fetch("http://localhost:3001/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ price: totalPrice }),
    //   })
    //     .then((res) => {
    //       return res.json();
    //     })
    //     .then((data) => {
    //       setClientSecret(data.clientSecret);
    //     });
  }, [email]);

  useEffect(() => {
    setProjects([]);

    const query = ref(db, "users");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          // console.log(project);
          if (project.email === email) {
            setProjects((projects) => [...projects, project]);
            setData((projects) => [...projects, project]);
          }
        });
      }
    });

    //   fetch("http://localhost:3001/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ price: totalPrice }),
    //   })
    //     .then((res) => {
    //       return res.json();
    //     })
    //     .then((data) => {
    //       setClientSecret(data.clientSecret);
    //     });
  }, [email]);

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    navigate("/");
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      let modifieduser = JSON.parse(user);
      setEmail(modifieduser.email);
      setCompanyName(modifieduser.name);
      setUid(modifieduser.uid);
    } else {
      navigate("/profile");
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      
      <div id="wrapper">
        <ul
          class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          <a
            class="sidebar-brand d-flex align-items-center justify-content-center"
            href="/billing"
          >
            <div class="sidebar-brand-icon rotate-n-15">
              <i class="fas fa-laugh-wink"></i>
            </div>
            <div class="sidebar-brand-text mx-3">My Profile</div>
          </a>

          <hr class="sidebar-divider my-0" />

          <li class="nav-item active">
            <a class="nav-link" href="/billing">
              <i class="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/chat">
              <i class="fas fa-fw far fa-comment"></i>
              <span>Configuration</span>
            </a>
          </li>

          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/profile">
              <i class="fas fa-fw far fa-user"></i>
              <span>Profile</span>
            </a>
          </li>

          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/api-docs">
              <span>API Doc</span>
            </a>
          </li>

          <hr class="sidebar-divider d-none d-md-block" />

          {/*           
            <div class="text-center d-none d-md-inline">
                <button class="rounded-circle border-0" id="sidebarToggle"></button>
            </div> */}

          <lottie-player
            src="https://assets7.lottiefiles.com/packages/lf20_jh9gfdye.json"
            background="transparent"
            speed="1"
            style={{ width: "100%", height: "300px" }}
            loop
            autoplay
          ></lottie-player>
        </ul>

        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              <button
                id="sidebarToggleTop"
                class="btn btn-link d-md-none rounded-circle mr-3"
              >
                <i class="fa fa-bars"></i>
              </button>

              <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button">
                      <i class="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown no-arrow d-sm-none">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="searchDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-search fa-fw"></i>
                  </a>
                </li>

                <div class="topbar-divider d-none d-sm-block"></div>

                {/* <!-- Nav Item - User Information --> */}
                <li class="nav-item dropdown no-arrow">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="mr-2 d-none d-lg-inline text-gray-600 small">
                      {email}
                    </span>
                    <img
                      class="img-profile rounded-circle"
                      src="img/undraw_profile.svg"
                    />
                  </a>
                  {/* <!-- Dropdown - User Information --> */}
                  <div
                    class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                  >
                    <a class="dropdown-item" href="/profile">
                      <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profile
                    </a>

                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" onClick={logout}>
                      <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            {/* <!-- End of Topbar -->

                <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800"></h1>

                <ExportCSV csvData={data} fileName="text-excel-doc" />
              </div>

              {/* <!-- Content Row --> */}

              <div class="row">
                {/* <!-- Area Chart --> */}
                <div class="col-xl-12 col-lg-7">
                  <div class="card shadow mb-4">
                    {/* <!-- Card Header - Dropdown --> */}
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 font-weight-bold text-primary">Profile</h6>
                      <div class="dropdown no-arrow">
                        <a
                          class="dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </a>
                        <div
                          class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <div class="dropdown-header">Dropdown Header:</div>
                          <a class="dropdown-item" href="#">
                            Action
                          </a>
                          <a class="dropdown-item" href="#">
                            Another action
                          </a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="#">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Card Body --> */}
                    <div class="card-body">
                      <div class="chatbody">
                        <div class="panel panel-primary">
                          <div
                            class="panel-body msg_container_base"
                            style={{ height: 1500 }}
                          >
                            <div class="row">
                              <div class="col mb-3">
                                <div class="card">
                                  <div class="card-body">
                                    <div class="e-profile">
                                      <div class="row">
                                        <div class="col-12 col-sm-auto mb-3">
                                          <div class="mx-auto">
                                            <div class="d-flex justify-content-center align-items-center rounded"></div>
                                          </div>
                                        </div>
                                        <div class="col d-flex flex-column flex-sm-row justify-content-between mb-3">
                                          <div class="text-center text-sm-left mb-2 mb-sm-0">
                                            <h4 class="pt-sm-2 pb-1 mb-0 text-nowrap">
                                              {companyName}
                                            </h4>
                                            {/* <p class="mb-0">{email}</p> */}
                                          </div>
                                          <div class="text-center text-sm-right">
                                            <span class="badge badge-secondary">
                                              user
                                            </span>
                                            {/* <div class="text-muted"><small>Joined 09 Dec 2017</small></div> */}
                                          </div>
                                        </div>
                                      </div>
                                      <ul class="nav nav-tabs">
                                        <li class="nav-item">
                                          <a href="" class="active nav-link">
                                            Settings
                                          </a>
                                        </li>
                                      </ul>
                                      <div class="tab-content pt-3">
                                        <div class="tab-pane active">
                                          <form
                                            class="form"
                                            novalidate=""
                                            onSubmit={SaveChanges}
                                          >
                                            <div class="row">
                                              <div class="col">
                                                <div class="row">
                                                  <div class="col">
                                                    <div class="form-group">
                                                      <label>
                                                        Company Name
                                                      </label>
                                                      <input
                                                        class="form-control"
                                                        type="text"
                                                        name="name"
                                                        value={companyName}
                                                        onChange={(e) =>
                                                          setCompanyName(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="Company Name"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div class="col">
                                                    <div class="form-group">
                                                      <label>
                                                        User full name
                                                      </label>
                                                      <input
                                                        class="form-control"
                                                        type="text"
                                                        name="username"
                                                        value={userFullName}
                                                        onChange={(e) =>
                                                          setUserFullName(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="User full name"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col">
                                                    <div class="form-group">
                                                      <label>Address</label>
                                                      <input
                                                        class="form-control"
                                                        type="text"
                                                        value={address}
                                                        onChange={(e) =>
                                                          setAddress(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="Address"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div class="col">
                                                    <div class="form-group">
                                                      <label>
                                                        Reset Password
                                                      </label>
                                                      <input
                                                        class="form-control"
                                                        type="text"
                                                        value={resetPassword}
                                                        onChange={(e) =>
                                                          setResetPassword(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="Reset Password"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col">
                                                    <div class="form-group">
                                                      <label>Country</label>
                                                      <input
                                                        class="form-control"
                                                        type="text"
                                                        value={country}
                                                        onChange={(e) =>
                                                          setCountry(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="Country"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col">
                                                    <div class="form-group">
                                                      <label>Email</label>
                                                      <input
                                                        class="form-control"
                                                        type="text"
                                                        value={email}
                                                        onChange={(e) =>
                                                          setEmail(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="Email"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col">
                                                    <div class="form-group">
                                                      <label>Website</label>
                                                      <input
                                                        class="form-control"
                                                        type="text"
                                                        value={website}
                                                        onChange={(e) =>
                                                          setWebsite(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="Website"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col">
                                                    <div class="form-group">
                                                      <label>
                                                        Contact number
                                                      </label>
                                                      <input
                                                        class="form-control"
                                                        type="text"
                                                        value={contact}
                                                        onChange={(e) =>
                                                          setContact(
                                                            e.target.value
                                                          )
                                                        }
                                                        placeholder="Contact number"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col d-flex justify-content-end">
                                                <button
                                                  class="btn btn-primary"
                                                  type="submit"
                                                >
                                                  Save Changes
                                                </button>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <!-- /.container-fluid --> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <footer class="sticky-footer bg-white">
            <div class="container my-auto">
              <div class="copyright text-center my-auto">
                <span>Copyright &copy; Your Website 2021</span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
    </>
  );
};

export default Profile;
