import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const AdNewModal = () => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [port, setPort] = useState("");
  const [tokenn, setToken] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const baseURL = process.env.REACT_APP_BASE_URL;

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.userId;


      const response = await axios.post(`${baseURL}/addconfig`, {
        type: type,
        email_id: email,
        e_password: password,
        port: port,
        token: tokenn,
        id: userId,
      });
      console.log(response.data);
      toast.info("Success!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });


      // Clear form fields
      setEmail("");
      setPassword("");
      setToken("");
      setPort("");
      setType("");

      window.location.reload();
    } catch (error) {
      console.error(error);

      toast.error("Error adding mail", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />

      <Button className="btn btn-primary btn-sm mx-2"
        style={{
          backgroundColor: 'skyblue',
          paddingRight: '3rem',
          paddingLeft: '3rem',
          border: 'none',
          fontSize: '16px',
          fontWeight: 'bold',
          color: 'black',
        }}
        onClick={handleShow}>
        Add New
      </Button>
      <Modal show={show} size="lg" centered style={{color: 'black'}}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Add New</Modal.Title>
        </Modal.Header>

        <Form onSubmit={submitForm}>
          <Modal.Body>
            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>AC type</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Email:</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Port</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={port}
                    onChange={(e) => {
                      setPort(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>
            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Token</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={tokenn}
                    onChange={(e) => {
                      setToken(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Send
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Exit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AdNewModal;
