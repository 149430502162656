import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token") || null, // Initialize token from local storage
  isAuthenticated: !!localStorage.getItem("token"), // Initialize isAuthenticated based on the presence of token in local storage
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = !!action.payload; // Update isAuthenticated based on the presence of token
      if (action.payload) {
        localStorage.setItem("token", action.payload); // Store token in local storage
      } else {
        localStorage.removeItem("token"); // Remove token from local storage
      }
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setToken, setIsAuthenticated } = authSlice.actions;
export const selectToken = (state) => state.auth.token;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export default authSlice.reducer;
