import React, { useState, useEffect } from "react";
import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import {ElementsConsumer, PaymentElement} from '@stripe/react-stripe-js';
import { db ,auth} from "./firebase";
import { onValue, ref,update } from "firebase/database";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";

  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
const totalPrice = 1400; // this means 14 usd and can also be calculated at the backend
const Checkout = ({emails}) => {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState("");
    const [projects, setProjects] = useState([]);
    const [email, setEmail] = useState(emails);
    const [payment, setPayment] = useState([]);
  
    const stripe = useStripe();
  const elements = useElements();

 
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uid, setUid] = useState('');

    const [uemail, setUemail] = useState('');


    const handleNewUserMessage = (newMessage) => {
      console.log(`New message incoming! ${newMessage}`);
      // Now send the message throught the backend API
    };

    useEffect(() => {
     let user= localStorage.getItem("user");
     if(user){
        let modifieduser= JSON.parse(user);
        setUemail(modifieduser.email);
        //setUid(modifieduser.uid);
     }
     else{
        // navigate("/");  
     }
    
   },[]);
  
    // create a payment intent
    useEffect(() => {

        

        const query = ref(db, "users");
        return onValue(query, (snapshot) => {
          const data = snapshot.val();
    
          if (snapshot.exists()) {
            Object.values(data).map((project,i) => {
               // console.log(project);
                if(project.email===emails){
                    setProjects((projects) => [...projects, project]);
                   // if(Object.keys(data)[i] !==''){
                      setUid(Object.keys(data)[i]);
                      console.log({uid});
                   // }
                    
                }
              
            });
          }

        });


     

        
        






    //   fetch("http://localhost:3001/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ price: totalPrice }),
    //   })
    //     .then((res) => {
    //       return res.json();
    //     })
    //     .then((data) => {
    //       setClientSecret(data.clientSecret);
    //     });
    }, []);
    const cardStyle = {
      style: {},
    };
  
    // // handle input errors
    // const handleChange = async (event) => {
    //   setDisabled(event.empty);
    //   setError(event.error ? event.error.message : "");
    // };
  
    // const handleSubmit = async (ev) => {
    //   ev.preventDefault();
    //   setProcessing(true);
    //   const payload = await stripe.confirmCardPayment(clientSecret, {
    //     payment_method: {
    //       card: elements.getElement(CardElement),
    //     },
    //   });
    //   if (payload.error) {
    //     setError(`Payment failed ${payload.error.message}`);
    //     setProcessing(false);
    //   } else {
    //     setError(null);
    //     setProcessing(false);
    //     setSucceeded(true);
    //   }
    // };


    useEffect(() => {
        if (!stripe) {
          return;
        }
    
        const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );
    
        if (!clientSecret) {
          return;
        }
    
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
          switch (paymentIntent.status) {
            case "succeeded":
              setMessage("Payment succeeded!");
              break;
            case "processing":
              setMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              setMessage("Your payment was not successful, please try again.");
              break;
            default:
              setMessage("Something went wrong.");
              break;
          }
        });
      }, [stripe]);
    
      const handleSubmit = async (e) => {

        e.preventDefault();
    
        // if (!stripe || !elements) {
        //   // Stripe.js has not yet loaded.
        //   // Make sure to disable form submission until Stripe.js has loaded.
        //   return;
        // }
    
        setIsLoading(true);
    //setPayment
    
        const msg = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: "http://localhost:3000/billing",
          },
          redirect: 'if_required' 
        });

        const error=msg.error;
        console.log({msg});
     //  update(ref(db, `/${uid}/pauymenyhistory`), msg);
     //   db.ref().child('/users/' + uid).set(msg);

     toast.success("Success", {
      position: toast.POSITION.TOP_CENTER
    });

     db.ref(`users/${uid}`).set({
      payment_history: msg,
     
    });





        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
    
        setIsLoading(false);
       // alert('success');
    
       //update

      };
    
      const paymentElementOptions = {
        layout: "tabs"
      }
    
    
  return (
    <div>

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
<ToastContainer />


      <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(email)}
        value={emails}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-primary ">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>

    
    
  


       
   
    </div>
  )
}

export default Checkout
