import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { BsFillPencilFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";

const TestModal = () => {
  const [show, setShow] = useState(false);
  const [toEmail, setToEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [ending, setEnding] = useState("");
  const [sendEmail, setSendemail] = useState("");

  const baseURL = process.env.REACT_APP_BASE_URL;


  const submitForm = async (e) => {
    e.preventDefault();
    try {

      const response = await axios.post(`${baseURL}/addemail`, {
        subject: subject,
        to_email: toEmail,
        body: body,
        ending: ending,
      });
      console.log(response.data);
      toast.info("Success!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Clear form fields
      setToEmail("");
      setBody("");
      setSubject("");
      setEnding("");

      // window.location.reload();

    } catch (error) {
      console.error(error);

      toast.error("Error testing mail", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };


  const handleShow = () => { setShow(true); };
  const handleClose = () => setShow(false);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />


      <Button className="btn btn-sm mx-2 btn-light"
        style={{ paddingRight: '1.5rem', paddingLeft: '1.5rem', borderColor: 'skyblue', borderWidth: '2px', }}
        onClick={handleShow}>
        Test
      </Button>
      <Modal show={show} size="md" centered style={{color: 'black'}}>
        {/* <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Test</Modal.Title>
        </Modal.Header> */}

        <Form onSubmit={submitForm}>
          <Modal.Body>
            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={4}>
                  <Form.Label>To Email</Form.Label>
                </Col>
                <Form.Control
                  type="email"
                  id="exampleInputEmail"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email Address..."
                  value={toEmail}
                  onChange={(e) => {
                    setToEmail(e.target.value);
                  }}
                />
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Subject:</Form.Label>
                </Col>
                <Form.Control
                  type="text"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                />
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Body</Form.Label>
                </Col>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={body}
                  onChange={(e) => {
                    setBody(e.target.value);
                  }}
                />
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Ending</Form.Label>
                </Col>
                <Form.Control
                  type="text"
                  value={ending}
                  onChange={(e) => {
                    setEnding(e.target.value);
                  }}
                />
              </Form.Group>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <div>
                <p className="mr-5" style={{ color: 'red', paddingRight: '22px', }}>Please check your email inbox</p>
              </div>
              <Button className="ml-5" variant="primary" type="submit" onClick={handleClose}>
                Send
              </Button>
              <Button variant="danger" onClick={handleClose} className="ml-2">
                Exit
              </Button>
            </div>
          </Modal.Footer>

        </Form>
      </Modal>
    </>
  );
}

export default TestModal;