import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const validatePassword = () => {
    const minLengthError = validateMinLength(newPassword);
    const containsLowercaseError = validateContainsLowercase(newPassword);
    const containsUppercaseError = validateContainsUppercase(newPassword);
    const containsNumberOrSymbolError =
      validateContainsNumberOrSymbol(newPassword);

    if (minLengthError) {
      toast.error(minLengthError);
      return false;
    }
    if (containsLowercaseError) {
      toast.error(containsLowercaseError);
      return false;
    }
    if (containsUppercaseError) {
      toast.error(containsUppercaseError);
      return false;
    }
    if (containsNumberOrSymbolError) {
      toast.error(containsNumberOrSymbolError);
      return false;
    }
    return true;
  };

  const validateMinLength = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long";
    }
    return "";
  };

  const validateContainsLowercase = (password) => {
    if (!/[a-z]/.test(password)) {
      return "Password must contain at least one lowercase letter";
    }
    return "";
  };

  const validateContainsUppercase = (password) => {
    if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one uppercase letter";
    }
    return "";
  };

  const validateContainsNumberOrSymbol = (password) => {
    if (!/[\d!@#$%^&*()_+]/.test(password)) {
      return "Password must contain at least one number or special character";
    }
    return "";
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    // Validate password
    const isPasswordValid = validatePassword();
    if (!isPasswordValid) {
      return;
    }

    try {
      const resetToken = new URLSearchParams(window.location.search).get(
        "token"
      );

      const response = await axios.post(`${baseURL}/reset-password`, {
        resetToken,
        newPassword,
        confirmPassword,
      });

      if (response.status === 200) {
        setSuccessMessage("Password reset successfully");
        toast.success("Password reset successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Clear form fields
        setNewPassword("");
        setConfirmPassword("");
        navigate("/login"); // Redirect to login page after successful password reset
      }
    } catch (error) {
      setError(error.response.data.error || "Error resetting password");
      toast.error(error.response.data.error || "Error resetting password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="login-19">
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-2">
                            Reset Your Password
                          </h1>
                          <p className="mb-4">
                            Enter your new password to reset your password.
                          </p>
                        </div>
                        <form onSubmit={handleResetPassword}>
                          <div className="form-group">
                            <label htmlFor="newPassword">New Password:</label>
                            <input
                              type="password"
                              className="form-control form-control-user"
                              id="newPassword"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="confirmPassword">
                              Confirm Password:
                            </label>
                            <input
                              type="password"
                              className="form-control form-control-user"
                              id="confirmPassword"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                          <button
                            className="btn btn-primary btn-lg btn-theme"
                            type="submit"
                          >
                            Reset Password
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
