// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyADRhO7loBM-OZwzsdH_I1LRhk4YvWkeug",
//   authDomain: "ecommerce-65e95.firebaseapp.com",
//   databaseURL: "https://ecommerce-65e95-default-rtdb.firebaseio.com",
//   projectId: "ecommerce-65e95",
//   storageBucket: "ecommerce-65e95.appspot.com",
//   messagingSenderId: "1047245659972",
//   appId: "1:1047245659972:web:1c5870369470cf33858111"
// };


const firebaseConfig = {

  apiKey: "AIzaSyCvHU-rK5G7Z1StBzqmSM8unYjliX8UTNA",
  authDomain: "chatbuddy-671e1.firebaseapp.com",
  databaseURL: "https://chatbuddy-671e1-default-rtdb.firebaseio.com",
  projectId: "chatbuddy-671e1",
  storageBucket: "chatbuddy-671e1.appspot.com",
  messagingSenderId: "614723737445",
  appId: "1:614723737445:web:5afc9c35aed9cf84f9f38c",
  measurementId: "G-F57DVR97Z9"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getDatabase(app);
export default app;