import React, { useEffect, useState } from "react";
import "./Billing.css";
import Checkout from "./Checkout";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { db, auth, child, push } from "./firebase";
import { onValue, ref, set, update } from "firebase/database";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import {
  selectUserId,
  selectUserInfo,
  setUserId,
  setUserInfo,
} from "./store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, setToken } from "./store/slices/authSlice";
const stripePromise = loadStripe(
  "pk_test_51LnMTTH7bYn4KIwLbgZZFQww66bsnJn8fZJMnCh8BNUs7VZWQ3Sj2IoPgOrSTEBBgT7yYKpahzAJyBIEljRdIMpX002iZTvKiC"
);

// This values are the props in the UI

const currency = "USD";
const style = { layout: "vertical" };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ currency, showSpinner, amount, uid }) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]);

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <ToastContainer />
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function () {
            let token = 0;
            if (parseInt(amount) === 10) {
              token = 300000;
            } else if (parseInt(amount) === 5) {
              token = 150000;
            } else if (parseInt(amount) === 2) {
              token = 60000;
            } else {
              token = 0;
            }

            toast.success(
              "Payment Success " + token + " Token added Successfully ",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            ); //alert('success');
            // Your code here after capture the order
            console.log(uid);

            update(ref(db, "users/" + uid + "/payment_history"), {
              amount: amount,
              data: data,
              purchased: token,
            });

            setTimeout(() => {
              window.location.reload(false);
            }, 5000);

            //  db.child("users/"+uid).child("payment").child("amount").setValue(amount)
          });
        }}
      />
    </>
  );
};

const Billing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);

  const userInfo = useSelector(selectUserInfo);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [paymethod, setPaymethod] = useState("paypal");
  const [pay, setPay] = useState(10);

  const [projects, setProjects] = useState([]);

  const [plans, setPlans] = useState([]);
  const [uid, setUid] = useState("");

  const [clientSecret, setClientSecret] = useState("");
  var itemstofilter = [];

  const [totalResponseProcessed, setTotalResponseProcessed] = useState(0);
  const [totalIssues, setTotalIssues] = useState(0);

  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      let token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId;
        console.log(userId);
        try {
          // Fetch total response processed count for the user
          const responseProcessed = await axios.get(`${baseURL}/emails/count/${userId}`);
          setTotalResponseProcessed(responseProcessed.data.count);
  
          // Fetch total issues count using another API call
          const totalIssues = await axios.get(`${baseURL}/count/${userId}`);
          setTotalIssues(totalIssues.data.count);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        console.log("No token available");
      }
    };
  
    fetchData();
  }, []);

  


  // create a payment intent
  useEffect(() => {
    setProjects([]);

    const query = ref(db, "users");
    onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          // console.log(project);
          if (project.email === email) {
            setProjects((projects) => [...projects, project]);
            // setUid(project.uid);
          }
        });
      }
    });

    const queryplan = ref(db, "plan");
    onValue(queryplan, (snapshot) => {
      const dataplan = snapshot.val();

      if (snapshot.exists()) {
        Object.values(dataplan).map((plan) => {
          // console.log(project);
          //if(project.email===email){
          setPlans((plans) => [...plans, plan]);
          // }
        });
      }
    });

    //   fetch("http://localhost:3001/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ price: totalPrice }),
    //   })
    //     .then((res) => {
    //       return res.json();
    //     })
    //     .then((data) => {
    //       setClientSecret(data.clientSecret);
    //     });
  }, [email]);

  const creditUsedn = (projects) => {
    let total = 0;
    projects &&
      Object.keys(projects).map((item, i) => {
        projects &&
          projects[item].searches &&
          Object.keys(projects[item]?.searches).map((search, j) => {
            total = total + parseInt(projects[item].searches[search].credit);
          });
      });
    return total;
  };

  const totalsearch = (projects) => {
    let total = 0;
    projects &&
      Object.keys(projects).map((item, i) => {
        projects &&
          projects[item].searches &&
          Object.keys(projects[item]?.searches).map((search, j) => {
            total = total + (i + 1);
          });
      });
    return total;
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(
      "http://localhost:85/chatbudy/create.php",
      { mode: "cors" },
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
      }
    )
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const logout = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/logout`);
      if (response.status === 200) {
        localStorage.removeItem("token");
        dispatch(setToken(null));
        navigate("/");
      }
    } catch (error) {
      console.error("Logout error:", error);
      // Handle logout error (e.g., show error message to user)
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId;
        dispatch(setUserId(userId));
        // localStorage.setItem("userId", JSON.stringify(userId)); // Store userId in localStorage
        try {
          const response = await axios.get(`${baseURL}/user/${userId}`);
          const userData = response.data.user;
          dispatch(setUserInfo(userData));
          //   localStorage.setItem("user", JSON.stringify(userData)); // Store userData in localStorage
          //   console.log("user data", userData);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        navigate("/billing");
      }
    };

    fetchUserData();
  }, [baseURL, dispatch, navigate, token]);
  useEffect(() => {
    // let user = localStorage.getItem("user");
    // console.log("user", user);
    if (userInfo) {
      //   let modifieduser = JSON.parse(user);
      setEmail(userInfo.email);
      setName(userInfo.name);
      setUid(userInfo.id);
    } else {
      navigate("/");
    }
  }, [navigate, userInfo]);

  //   useEffect(() => {
  //     if (token) {
  //       const decodedToken = jwtDecode(token);
  //       const userId = decodedToken.userId;
  //       dispatch(setUserId(userId));
  //       fetchUserData(userId);
  //       console.log("id", userId);
  //     } else {
  //       navigate("/billing");
  //     }
  //   }, [dispatch, navigate]);

  //   const fetchUserData = async (userId) => {
  //     try {
  //       const response = await axios.get(`${baseURL}/user/${userId}`); // Adjust the endpoint URL based on your backend API
  //       const userData = response.data.user;
  //       console.log("user data", userData);
  //       setEmail(userData.email);
  //       setName(userData.user_full_name);
  //       //setUid(userData.id);
  //       dispatch(setUserId(userId));
  //       console.log("user_full_name", userData);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //       // Handle error (e.g., show error message to user)
  //     }
  //   };

  return (
    <>
      <div id="wrapper">
        <ul
          class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          <a
            class="sidebar-brand d-flex align-items-center justify-content-center"
            href="/billing"
          >
            <div class="sidebar-brand-icon rotate-n-15">
              <i class="fas fa-laugh-wink"></i>
            </div>
            <div class="sidebar-brand-text mx-3">Chat buddy</div>
          </a>

          <hr class="sidebar-divider my-0" />

          <li class="nav-item active">
            <a class="nav-link" href="#">
              <i class="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/chat">
              <i class="fas fa-fw  far fa-comment"></i>
              <span>Configuration</span>
            </a>
          </li>
          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/profile">
              <i class="fas fa-fw far fa-user"></i>
              <span>Profile</span>
            </a>
          </li>
          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/api-docs">
              <span>API Doc</span>
            </a>
          </li>

          <hr class="sidebar-divider d-none d-md-block" />

          {/*           
            <div class="text-center d-none d-md-inline">
                <button class="rounded-circle border-0" id="sidebarToggle"></button>
            </div> */}

          <lottie-player
            src="https://assets7.lottiefiles.com/packages/lf20_jh9gfdye.json"
            background="transparent"
            speed="1"
            style={{ width: "100%", height: "300px" }}
            loop
            autoplay
          ></lottie-player>
        </ul>

        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              <button
                id="sidebarToggleTop"
                class="btn btn-link d-md-none rounded-circle mr-3"
              >
                <i class="fa fa-bars"></i>
              </button>

              <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button">
                      <i class="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown no-arrow d-sm-none">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="searchDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-search fa-fw"></i>
                  </a>
                </li>

                <div class="topbar-divider d-none d-sm-block"></div>

                {/* <!-- Nav Item - User Information --> */}
                <li class="nav-item dropdown no-arrow">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="mr-2 d-none d-lg-inline text-gray-600 small">
                      {email}
                    </span>
                    <img
                      class="img-profile rounded-circle"
                      src="img/undraw_profile.svg"
                    />
                  </a>
                  {/* <!-- Dropdown - User Information --> */}
                  <div
                    class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                  >
                    <a class="dropdown-item" href="/profile">
                      <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profile
                    </a>

                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" onClick={logout}>
                      <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            {/* <!-- End of Topbar -->

                <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
                <a
                  href="#"
                  class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                >
                  <i class="fas fa-download fa-sm text-white-50"></i> Generate
                  Report
                </a>
              </div>

              {/* <!-- Content Row --> */}
              <div class="row">
                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-primary shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            Total request received
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            <div>
                              <div className="input-label">
                                {/* {creditUsedn(projects)} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-calendar fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-success shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                            Total request processed
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {totalResponseProcessed}
                            {projects &&
                              Object.keys(projects).map((item, i) => (
                                <div>
                                  <div className="input-label">
                                    {((parseInt(projects[item].credit) || 0) +
                                      parseInt(
                                        projects[item]?.payment_history
                                          ?.purchased
                                      ) || 0) -
                                      (parseInt(creditUsedn(projects)) || 0)}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Earnings (Monthly) Card Example --> */}
                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-info shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                            Total issues
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {totalIssues}
                            {projects &&
                              Object.keys(projects).map((item, i) => (
                                <div>
                                  <div className="input-label">
                                    {parseInt(
                                      projects[item]?.payment_history?.purchased
                                    ) || 0}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Pending Requests Card Example --> */}
                <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                            Total credit left
                          </div>
                          <div class="h5 mb-0 font-weight-bold text-gray-800">
                            {totalsearch(projects)}

                            {/* {
                                        projects &&  Object.keys(projects).map((item, i) => (
               
            

           
            
      projects &&  projects[item].searches ?   projects[item].searches.length : 0

            
            
      


    ))
        
        
        } */}
                          </div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-comments fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Content Row --> */}

              <div class="row">
                {/* <!-- Area Chart --> */}
                <div class="col-xl-8 col-lg-7">
                  <div class="card shadow mb-4">
                    {/* <!-- Card Header - Dropdown --> */}
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Buy Credits
                      </h6>
                      <div class="dropdown no-arrow">
                        <a
                          class="dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </a>
                        <div
                          class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <div class="dropdown-header">Dropdown Header:</div>
                          <a class="dropdown-item" href="#">
                            Action
                          </a>
                          <a class="dropdown-item" href="#">
                            Another action
                          </a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="#">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Card Body --> */}
                    <div class="card-body">
                      <div class="chart-area">
                        <div class="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Amount
                          </label>
                          <select
                            class="form-select form-control"
                            aria-label="Default select example"
                            onChange={(e) => setPay(e.target.value)}
                          >
                            <option selected>select Payment</option>
                            <option value="10" selected>
                              10 USD | 300,000 Tokens
                            </option>
                            <option value="2">2 USD | 60,000 Tokens</option>
                            <option value="5">5 USD | 150,000 Tokens</option>
                          </select>
                        </div>

                        <div class="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Payment Method
                          </label>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              onClick={(e) => setPaymethod("paypal")}
                              checked
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Paypal
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              onClick={(e) => setPaymethod("stripe")}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault2"
                            >
                              Stripe
                            </label>
                          </div>
                        </div>

                        {paymethod !== "" &&
                        paymethod == "stripe" &&
                        clientSecret ? (
                          <Elements options={options} stripe={stripePromise}>
                            <Checkout emails={email} />
                          </Elements>
                        ) : paymethod === "paypal" ? (
                          <>
                            <PayPalScriptProvider
                              options={{
                                "client-id": "test",
                                components: "buttons",
                                currency: "USD",
                              }}
                            >
                              <ButtonWrapper
                                currency={currency}
                                amount={pay}
                                showSpinner={false}
                                uid={uid}
                              />
                            </PayPalScriptProvider>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- Pie Chart --> */}
                <div class="col-xl-4 col-lg-5">
                  <div class="card shadow mb-4">
                    {/* <!-- Card Header - Dropdown --> */}
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Recent Purchases
                      </h6>
                      <div class="dropdown no-arrow">
                        <a
                          class="dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </a>
                        <div
                          class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <div class="dropdown-header">Dropdown Header:</div>
                          <a class="dropdown-item" href="#">
                            Action
                          </a>
                          <a class="dropdown-item" href="#">
                            Another action
                          </a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="#">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class=" pt-4 pb-2">
                        <div class="table-responsive">
                          <table
                            class="table table-bordered"
                            width="100%"
                            cellspacing="0"
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Reference</th>
                              </tr>
                            </thead>
                            <tfoot>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>credits</th>
                              </tr>
                            </tfoot>
                            <tbody>
                              {projects &&
                                Object.keys(projects).map(
                                  (item, i) =>
                                    projects &&
                                    projects[item].searches &&
                                    Object.keys(projects[item]?.searches).map(
                                      (search, j) =>
                                        j < 5 ? (
                                          <tr>
                                            {" "}
                                            <td>{j}</td>
                                            <td>
                                              {
                                                projects[item].searches[search]
                                                  .description
                                              }
                                            </td>
                                            <td>
                                              {
                                                projects[item].searches[search]
                                                  .credit
                                              }
                                            </td>
                                          </tr>
                                        ) : (
                                          ""
                                        )
                                    )
                                )}
                            </tbody>{" "}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <footer class="sticky-footer bg-white">
            <div class="container my-auto">
              <div class="copyright text-center my-auto">
                <span>Copyright &copy; Your Website 2021</span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
    </>
  );
};

export default Billing;
