import React,{ useEffect,useState } from "react";




export const UserContext = React.createContext();


export const UserProvider = ({ children }) => {
  
  const [currentAccount, setCurrentAccount] = useState('');



  useEffect(() => {
    
   // createSale('test','0.001');
  }, []);

  return (
    <UserContext.Provider value={{ currentAccount }}>
      {children}
    </UserContext.Provider>
  );
};