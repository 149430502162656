import React, { useEffect, useState } from "react";
import "./Billing.css";
import Checkout from "./Checkout";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { db, auth, child, push } from "./firebase";
import { onValue, ref, set, update } from "firebase/database";
import axios from "axios";
import { ExcelExport } from "./Excel/ExcelExport";
import { ExportCSV } from "./Excel/ExportToCSV";
import { useSpeechSynthesis } from "react-speech-kit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import * as fs from "fs";
import { Document, Packer, Paragraph, TextRun } from "docx";
import Download from "@axetroy/react-download";
// import MDocument from './MDocument';
import * as docx from "docx";
import { jwtDecode } from "jwt-decode";
import useSound from "use-sound";
import { v4 as uuidv4 } from "uuid";
import boopSfx from "./chat.mp3";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import AdNewModal from "./components/modals/AdNewModal";
import TestModal from "./components/modals/TestModal";
import UpdateConfigMail from "./components/modals/UpdateConfigMail";
import {
  selectUserId,
  selectUserInfo,
  setUserId,
  setUserInfo,
} from "./store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

const stripePromise = loadStripe(
  "pk_test_51LnMTTH7bYn4KIwLbgZZFQww66bsnJn8fZJMnCh8BNUs7VZWQ3Sj2IoPgOrSTEBBgT7yYKpahzAJyBIEljRdIMpX002iZTvKiC"
);
const Chat = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [show, setShow] = useState("none");
  const [companyId, setCompanyId] = useState("");
  const [type, setType] = useState("");
  const [port, setPort] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [mailData, setMailData] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [prevMails, setPrevMails] = useState([]);
  const [projects, setProjects] = useState([]);
  const [configid, SetConfigid] = useState("");
  // const { speak } = useSpeechSynthesis();
  const [clientSecret, setClientSecret] = useState("");
  var itemstofilter = [];

  //const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");

  const [uid, setUid] = useState("");
  const [msg, setMsg] = useState([]);
  const [data, setData] = useState([]);
  const [credit, setCredit] = useState("");
  const [creditBalance, setCreditBalance] = useState("");
  const [creditUsed, setCreditUsed] = useState("");

  const onEnd = () => {
    //setHighlightedText('')
  };

  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
    onEnd,
  });

  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      fetchMailData(decodedToken.userId);
    } else {
      navigate("/");
    }
  }, []);

  const fetchMailData = async (userId) => {
    try {
      const response = await axios.get(`${baseURL}/fetch/${userId}`);
      const dataTable = response.data.data;
      setDataTable(dataTable);
    } catch (error) {
      console.error("Error fetching mail data:", error);
    }
  };

  // Function to hide the hashed password
  const hidePassword = () => '●●●●●●';

  console.log(fetchMailData);

  const handleDeleteMail = (configmailid) => {
    if (window.confirm("Are you sure you want to delete this mail?")) {
      axios
        .delete(`${baseURL}/deleteconfig/${configmailid}`)
        .then((response) => {
          if (response.data.success) {
            setPrevMails((prevMails) => {
              return prevMails.filter((mail) => mail.id !== configmailid);
            });
            toast.success("Mail deleted successfully!");
            // No need to reload the entire page, you can update the state and rerender the component
          } else {
            toast.error("An error occurred while deleting the mail.");
          }
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          toast.error("An error occurred while deleting the mail.");
        });
    }
  };

  const logout = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/logout`);
      if (response.status === 200) {
        localStorage.removeItem("token");
        dispatch(setToken(null));
        navigate("/login");
      }
    } catch (error) {
      console.error("Logout error:", error);
      // Handle logout error (e.g., show error message to user)
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId;
        dispatch(setUserId(userId));
        // localStorage.setItem("userId", JSON.stringify(userId)); // Store userId in localStorage
        try {
          const response = await axios.get(`${baseURL}/user/${userId}`);
          const userData = response.data.user;
          dispatch(setUserInfo(userData));
          //   localStorage.setItem("user", JSON.stringify(userData)); // Store userData in localStorage
          //   console.log("user data", userData);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        navigate("/");
      }
    };

    fetchUserData();
  }, [baseURL, dispatch, navigate, token]);
  useEffect(() => {
    // let user = localStorage.getItem("user");
    // console.log("user", user);
    if (userInfo) {
      //   let modifieduser = JSON.parse(user);
      setEmail(userInfo.email);
      setCompanyName(userInfo.name);
      setUid(userInfo.id);
    } else {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const [play] = useSound(boopSfx);
  const handleSubmit = (e) => {
    e.preventDefault();
    var spomp = `explain for 30 year old person ${prompt}`;

    setShow("block");
    // Send a request to the server with the prompt
    axios
      .post("http://chatbaddybe.rbdemo.live/chat", { prompt })
      .then((res) => {
        // Update the response state with the server's response
        setResponse(res.data.choices[0].text);
        play();

        //speak({ text: res.data.choices[0].text });
        setMsg([...msg, { req: prompt, res: res.data.choices[0].text }]);
        setShow("none");
        let uuid = uuidv4();
        const d = new Date();
        update(ref(db, "users/" + uid + "/searches/" + uuid), {
          credit: res.data.usage.completion_tokens,
          description: prompt,
          datetime: d,
        });

        //    update(ref(db, 'users/'+uid+'/credit/'), {
        //     credit-
        //            });
      })
      .catch((err) => {
        console.error(err);
        setShow("none");
      });
  };

  // create a payment intent
  useEffect(() => {
    setProjects([]);

    const query = ref(db, "users");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          // console.log(project);
          if (project.email === email) {
            setProjects((projects) => [...projects, project]);

            setCredit(project.credit);
            setCreditBalance(project.creditBalance);
            setCreditUsed(project.creditUsed);
          }
        });
      }
    });

    //   fetch("http://localhost:3001/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ price: totalPrice }),
    //   })
    //     .then((res) => {
    //       return res.json();
    //     })
    //     .then((data) => {
    //       setClientSecret(data.clientSecret);
    //     });
  }, [email]);

  useEffect(() => {
    setProjects([]);

    const query = ref(db, "users");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          // console.log(project);
          if (project.email === email) {
            setProjects((projects) => [...projects, project]);
            setData((projects) => [...projects, project]);
          }
        });
      }
    });

    //   fetch("http://localhost:3001/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ price: totalPrice }),
    //   })
    //     .then((res) => {
    //       return res.json();
    //     })
    //     .then((data) => {
    //       setClientSecret(data.clientSecret);
    //     });
  }, [email]);

  // const logout = (e) => {
  //   e.preventDefault();
  //   localStorage.removeItem("user");
  //   navigate("/");
  // };

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      let modifieduser = JSON.parse(user);
      setEmail(modifieduser.email);
      setCompanyName(modifieduser.name);
      setUserId(modifieduser.uid);
    } else {
      navigate("/chat");
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />

      <div id="wrapper">
        <ul
          class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          <a
            class="sidebar-brand d-flex align-items-center justify-content-center"
            href="/billing"
          >
            <div class="sidebar-brand-icon rotate-n-15">
              <i class="fas fa-laugh-wink"></i>
            </div>
            <div class="sidebar-brand-text mx-3">
              <img src="./logo-white.png" alt="logo" />
            </div>
          </a>

          <hr class="sidebar-divider my-0" />

          <li class="nav-item active">
            <a class="nav-link" href="/billing">
              <i class="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/chat">
              <i class="fas fa-fw far fa-comment"></i>
              <span>Configuration</span>
            </a>
          </li>

          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/profile">
              <i class="fas fa-fw far fa-user"></i>
              <span>Profile</span>
            </a>
          </li>
          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/api-docs">
              <span>API Doc</span>
            </a>
          </li>

          <hr class="sidebar-divider d-none d-md-block" />

          {/*           
            <div class="text-center d-none d-md-inline">
                <button class="rounded-circle border-0" id="sidebarToggle"></button>
            </div> */}

          <lottie-player
            src="https://assets7.lottiefiles.com/packages/lf20_jh9gfdye.json"
            background="transparent"
            speed="1"
            style={{ width: "100%", height: "300px" }}
            loop
            autoplay
          ></lottie-player>
        </ul>

        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              <button
                id="sidebarToggleTop"
                class="btn btn-link d-md-none rounded-circle mr-3"
              >
                <i class="fa fa-bars"></i>
              </button>

              <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button">
                      <i class="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown no-arrow d-sm-none">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="searchDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-search fa-fw"></i>
                  </a>
                </li>

                <div class="topbar-divider d-none d-sm-block"></div>

                {/* <!-- Nav Item - User Information --> */}
                <li class="nav-item dropdown no-arrow">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="mr-2 d-none d-lg-inline text-gray-600 small">
                      {email}
                    </span>
                    <img
                      class="img-profile rounded-circle"
                      src="img/undraw_profile.svg"
                    />
                  </a>
                  {/* <!-- Dropdown - User Information --> */}
                  <div
                    class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                  >
                    <a class="dropdown-item" href="/profile">
                      <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profile
                    </a>

                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" onClick={logout}>
                      <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            {/* <!-- End of Topbar -->

                <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800"></h1>

                <ExportCSV csvData={data} fileName="text-excel-doc" />
              </div>

              {/* <!-- Content Row --> */}

              <div class="row">
                {/* <!-- Area Chart --> */}
                <div class="col-xl-12 col-lg-7">
                  <div class="card shadow mb-4">
                    {/* <!-- Card Header - Dropdown --> */}
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Configuration
                      </h6>
                      <div class="dropdown no-arrow">
                        <a
                          class="dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </a>
                        <div
                          class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <div class="dropdown-header">Dropdown Header:</div>
                          <a class="dropdown-item" href="#">
                            Action
                          </a>
                          <a class="dropdown-item" href="#">
                            Another action
                          </a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="#">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Card Body --> */}
                    <div class="card-body">
                      <div class="chatbody">
                        <div class="panel panel-primary">
                          <div class="panel-body msg_container_base">
                            {/* <div class="row msg_container base_sent">
                        <div class="col-md-10 col-xs-10">
                            <div class="messages msg_sent">
                                <p>that mongodb thing looks good, huh?
                                tiny master db, and huge document store</p>
                                <time datetime="2009-11-13T20:00">Timothy • 51 min</time>
                            </div>
                        </div>
                        <div class="col-md-2 col-xs-2 avatar">
                            <img src="http://www.bitrebels.com/wp-content/uploads/2011/02/Original-Facebook-Geek-Profile-Avatar-1.jpg" class="img-profile rounded-circle"/>
                        </div>
                    </div>
                    <div class="row msg_container base_receive">
                        <div class="col-md-2 col-xs-2 avatar">
                            <img src="http://www.bitrebels.com/wp-content/uploads/2011/02/Original-Facebook-Geek-Profile-Avatar-1.jpg" class="img-profile rounded-circle "/>
                        </div>
                        <div class="col-md-10 col-xs-10">
                            <div class="messages msg_receive">
                                <p>that mongodb thing looks good, huh?
                                tiny master db, and huge document store</p>
                                <time datetime="2009-11-13T20:00">Timothy • 51 min</time>
                            </div>
                        </div>
                    </div> */}
                            {msg.map((m, i) => (
                              <>
                                <div class="row msg_container base_sent">
                                  <div class="col-md-10 col-xs-10">
                                    <div class="messages msg_sent">
                                      <p>{m.req}</p>
                                      {/* <time datetime="2009-11-13T20:00">Timothy • 51 min</time> */}
                                    </div>
                                  </div>
                                  <div class="col-md-2 col-xs-2 avatar">
                                    <img
                                      src="./img/undraw_profile.svg"
                                      class="img-profile rounded-circle"
                                    />
                                  </div>
                                </div>
                                <div class="row msg_container base_receive">
                                  <div class="col-md-2 col-xs-2 avatar">
                                    <img
                                      src="./img/robo.jpg"
                                      class="img-profile rounded-circle "
                                    />
                                  </div>
                                  <div class="col-md-10 col-xs-10">
                                    <div class="messages msg_receive">
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={m.res}
                                        onReady={(editor) => {
                                          // You can store the "editor" and use when it is needed.
                                          console.log(
                                            "Editor is ready to use!",
                                            editor
                                          );
                                        }}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                          console.log("Blur.", editor);
                                        }}
                                        onFocus={(event, editor) => {
                                          console.log("Focus.", editor);
                                        }}
                                      />
                                      <div className="d-flex flex-row mt-2">
                                        <button
                                          className="btn btn-sm btn-primary mr-1"
                                          onClick={() => speak({ text: m.res })}
                                        >
                                          <i class="fas fa-fw fa-microphone"></i>
                                        </button>
                                        <button
                                          className="btn btn-sm btn-danger mr-1"
                                          onClick={() =>
                                            cancel({ text: m.res })
                                          }
                                        >
                                          <i class="fas fa-fw fa-microphone-slash"></i>
                                        </button>
                                        <Download
                                          file="chatgpt.txt"
                                          content={m.res}
                                        >
                                          <button
                                            className="btn btn-sm btn-info"
                                            type="button"
                                          >
                                            <i class="fas fa-fw fa-download"></i>
                                          </button>
                                        </Download>
                                      </div>
                                      {/* <time datetime="2009-11-13T20:00">Timothy • 51 min</time> */}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}

                            <div
                              class="row msg_container base_receive"
                              style={{ display: show }}
                            >
                              <div class="col-md-2 col-xs-2 avatar">
                                <img
                                  src="./img/robo.jpg"
                                  class="img-profile rounded-circle "
                                />
                              </div>
                              <div class="col-md-10 col-xs-10">
                                <div class="messages msg_receive">
                                  <p>
                                    <img
                                      src="./img/loading.gif"
                                      width="100"
                                      style={{ width: "100px", height: "30px" }}
                                    ></img>
                                  </p>
                                  {/* <time datetime="2009-11-13T20:00">Timothy • 51 min</time> */}
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-end">
                              <AdNewModal />
                            </div>
                          </div>
                          <table className="table">
                            <thead
                              style={{
                                backgroundColor: "black",
                                color: "black",
                                color: "white",
                              }}
                            >
                              <tr>
                                <th scope="col">AC type</th>
                                <th scope="col">Email</th>
                                <th scope="col">Password</th>
                                <th scope="col">Port</th>
                                <th scope="col">Token</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody style={{ color: "black" }}>
                              {dataTable.map((dt) => (
                                <tr key={dt.id}>
                                  <td>{dt.type}</td>
                                  <td>{dt.email_id}</td>
                                  <td>{hidePassword()}</td>
                                  <td>{dt.port}</td>
                                  <td>{dt.token}</td>

                                  <td className="text-center" style={{ padding: '5px' }} >
                                    <TestModal />
                                    <UpdateConfigMail id={dt.id} />
                                    <button
                                      onClick={() => handleDeleteMail(dt.id)}
                                      className="btn btn-danger btn-sm rounded-circle"
                                    >
                                      <BsFillTrashFill />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {/* <div class="panel-footer">
                                                        <div class="input-group">
                                                            <input id="btn-input"
                                                                value={prompt}
                                                                onChange={(e) => setPrompt(e.target.value)}
                                                                type="text" class="form-control input-sm chat_input h-75" placeholder="Write your message here..." />
                                                            <span class="input-group-btn">
                                                                <button class="bg-1 btn" id="btn-chat" onClick={handleSubmit}><lottie-player class="btn-res-1" src="https://assets1.lottiefiles.com/packages/lf20_xaxycw1s.json" background="transparent" speed="1" loop autoplay></lottie-player></button>

                                                            </span>
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <footer class="sticky-footer bg-white">
            <div class="container my-auto">
              <div class="copyright text-center my-auto">
                <span>Copyright &copy; Your Website 2021</span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
    </>
  );
};

export default Chat;
