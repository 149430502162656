import React, { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";

import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
const Register = () => {
  const [company, setCompany] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");
  const [website, setWebsite] = useState("");
  const [contactno, setContactno] = useState("");
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    validateFields();
  }, [password, confirmPassword, email]);

  const validateFields = () => {
    const isPasswordValid = validatePassword();
    const isEmailValid = validateEmail();
    const isConfirmPasswordValid = validateConfirmPassword();

    return isPasswordValid && isEmailValid && isConfirmPasswordValid;
  };

  const resetForm = () => {
    setCompany("");
    setAddress("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setCountry("");
    setWebsite("");
    setContactno("");
    setFullname("");
    setUsername("");
    setError("");
    setPasswordError("");
    setEmailError("");
    setConfirmPasswordError("");
  };

  const validatePassword = () => {
    const containsLowercase = /^(?=.*[a-z]).*$/;
    const containsUppercase = /^(?=.*[A-Z]).*$/;
    const containsNumberOrSymbol = /^(?=.*[\d!@#$%^&*()_+]).*$/;

    let passwordErrorMessage = "";

    if (password.length > 8) {
      passwordErrorMessage = "Password must be at least 8 characters long. ";
    }
    if (!containsLowercase.test(password)) {
      passwordErrorMessage +=
        "Password must contain at least 1 lowercase letter. ";
    }
    if (!containsUppercase.test(password)) {
      passwordErrorMessage +=
        "Password must contain at least 1 uppercase letter. ";
    }
    if (!containsNumberOrSymbol.test(password)) {
      passwordErrorMessage +=
        "Password must contain at least 1 number or special character. ";
    }

    if (passwordErrorMessage) {
      setPasswordError(passwordErrorMessage);
      return false;
    }

    // If there are no validation errors, clear the password error
    setPasswordError("");
    return true;
  };

  const validateEmail = () => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validateConfirmPassword = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    validateEmail(value);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    validatePassword(value);
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    validateConfirmPassword(value);
  };

  const register = async (e) => {
    e.preventDefault();

    // Validate password
    const isPasswordValid = validatePassword();
    // Validate email
    const isEmailValid = validateEmail();
    // Validate confirm password
    const isConfirmPasswordValid = validateConfirmPassword();

    // If any validation fails, stop form submission
    if (!isPasswordValid || !isEmailValid || !isConfirmPasswordValid) {
      return;
    }

    try {
      // Send a request to the register endpoint
      const response = await axios.post(`${baseURL}/register`, {
        company_name: company, // Assuming name is the company name
        address,
        email,
        password,
        confirm_password: confirmPassword,
        country,
        website,
        contact_number: contactno,
        user_full_name: fullname,
        user_name: username,
      });

      // Handle successful registration
      console.log(response.data);
      toast.info("Success! Please verify your email address", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // Reset form fields and errors
      resetForm();
    } catch (error) {
      // Handle registration error
      console.error(error.response?.data?.error);
      if (
        error.response?.data?.error === "User with this email already exists"
      ) {
        toast.error("User with this email already exists", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setError(
          error.response?.data?.error || "An error occurred during registration"
        );
        toast.error("Error: An error occurred during registration", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <section className="login-19">
      <lottie-player
        class="robot-p-1"
        src="https://assets5.lottiefiles.com/packages/lf20_g7zx4ni5.json"
        background="transparent"
        speed="1"
        style={{ width: "200px", height: "200px" }}
        loop
        autoplay
      ></lottie-player>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="cube"></div>
      <div class="container">
        <ToastContainer />
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
              <div class="col-lg-7">
                <div class="p-5">
                  {/* iLooops EMailer Logo*/}
                  <h1>iLooops EMailer</h1>
                  {/* <a href="" class="logo">
                    <img src="./logo.png" alt="logo" />
                  </a> */}
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Create an Account!</h1>
                  </div>
                  <form class="user" onSubmit={register}>
                    <div class="form-group">
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleFullName"
                            placeholder="User Full Name"
                            value={fullname}
                            onChange={(e) => setFullname(e.target.value)}
                          />
                        </div>
                        <div class=" col-sm-6">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleCompanyName"
                            placeholder="Company Name"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                        </div>
                      </div>
                      <div class="form-group row ">
                        <div class=" col-sm">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleAddress"
                            placeholder="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      <div class="form-group row ">
                        <div class=" col-sm-6">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleCountry"
                            placeholder="Country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                        </div>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleContactno"
                            placeholder="Contact number"
                            value={contactno}
                            onChange={(e) => setContactno(e.target.value)}
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleEmail"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => handleEmailChange(e.target.value)}
                          />
                          {emailError && (
                            <small className="text-danger">{emailError}</small>
                          )}
                        </div>
                        <div class="col-sm-6">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleWebsite"
                            placeholder="Website"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="form-group">
                      <div class="form-group row">
                        <div class="col-sm-4">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleUserName"
                            placeholder="User name"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                        <div class="col-sm-4">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="examplePassword"
                            placeholder="Password"
                            value={password}
                            onChange={(e) =>
                              handlePasswordChange(e.target.value)
                            }
                          />
                          {passwordError && (
                            <small className="text-danger">
                              {passwordError}
                            </small>
                          )}
                        </div>
                        <div class="col-sm-4">
                          <input
                            type="text"
                            class="form-control form-control-user"
                            id="exampleConfirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) =>
                              handleConfirmPasswordChange(e.target.value)
                            }
                          />
                          {confirmPasswordError && (
                            <small className="text-danger">
                              {confirmPasswordError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="clearfix"></div>
                    <div class="form-group">
                      <button
                        class="btn btn-primary btn-lg btn-theme"
                        type="submit"
                      >
                        Register Account
                      </button>
                    </div>
                  </form>
                  <hr />
                  <div class="forgot-password-reg float-left">
                    <a class="small" href="/forgot-password">
                      Forgot Password?
                    </a>
                  </div>
                  <div class="login-reg float-right pb-3">
                    <a class="small" href="/login">
                      Already have an account? Login!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
