import React, { useState, useContext, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserProvider";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  selectToken,
  setIsAuthenticated,
  setToken,
} from "./store/slices/authSlice";
import { jwtDecode } from "jwt-decode";
import { setUserId, setUserInfo } from "./store/slices/userSlice";

//import { Navigate } from "react-router-dom";
const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (token) {
      // Trigger verification by making a request to the backend

      axios
        .get(`${baseURL}/verify-email?token=${token}`)
        .then((response) => {
          // Handle successful verification
          console.log(response.data.user.email);
          toast.success("Email verification successful. You can now login.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((error) => {
          // Handle verification error
          console.error("Email verification error:", error);
        });
      const decodedToken = jwtDecode(token);
      setEmail(decodedToken.email);
    }
  }, [baseURL]);

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/login`, {
        email,
        password,
      });
      const { token } = response.data;
      localStorage.setItem("token", JSON.stringify(token));

      dispatch(setToken(token));
      console.log("tok", token);
      toast.success("Login successful", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Clear form fields
      setEmail("");
      setPassword("");
      navigate("/billing");
    } catch (error) {
      toast.error("Invalid credentials", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Login error:", error);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />

      <section className="login-19">
        <lottie-player
          class="robot-p-1"
          src="https://assets1.lottiefiles.com/packages/lf20_xaxycw1s.json"
          background="transparent"
          speed="1"
          style={{ width: "200px", height: "200px" }}
          loop
          autoplay
        ></lottie-player>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="container">
          <div class="row login-box">
            <div class="col-lg-6 bg-img">
              <div class="info clearfix align-self-center">
                <div class="waviy">
                  <span>
                    Flexible, scalable,
                    <br /> and results driven
                    <br /> email sending
                    <br /> platform
                  </span>
                </div>

                <p>
                  Build with the platform that enables you to send, track, and
                  optimize emails effortlessly.
                </p>
                <p> Send email from multiple accounts</p>
                <ul class="social-list">
                  <li>
                    <a href="#" class="facebook-bg">
                      {/* <i class="fab fa-facebook-f"></i> */}
                      <span>Bulk Email</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" class="twitter-bg">
                      {/* <i class="fab fa-twitter"></i> */}
                      <span>API Integration</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" class="google-bg">
                      {/* <i class="fab fa-google"></i> */}
                      <span>Unlimited Accounts</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 form-section">
              <div class="form-inner">
                {/* iLooops EMailer Logo*/}
                <h1>iLooops EMailer</h1>
                {/* <a href="" class="logo">
                  <img src="./logo.png" alt="logo" />
                </a> */}
                <h3>Sign Into Your Account</h3>
                <form class="user">
                  <div class="form-group form-box clearfix">
                    <input
                      aria-describedby="emailHelp"
                      class="form-control form-control"
                      id="email-address"
                      name="email"
                      type="email"
                      required
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div class="form-group form-box clearfix">
                    <input
                      class="form-control form-control"
                      id="password"
                      name="password"
                      type="password"
                      required
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div class="clearfix"></div>
                  <div class="checkbox form-group form-box">
                    <div class="form-check float-left">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="rememberme"
                      />
                      <label class="form-check-label" for="rememberme">
                        Remember me
                      </label>
                    </div>
                    <a
                      href="/forgot-password"
                      class="link-light float-right forgot-password"
                    >
                      Forgot your password?
                    </a>
                  </div>
                  <div class="clearfix"></div>
                  <div class="form-group">
                    <a
                      href="#"
                      class="btn btn-primary btn-lg btn-theme"
                      fdprocessedid="t6kkhgu"
                      onClick={onLogin}
                    >
                      <span>Login</span>
                    </a>
                  </div>
                </form>
                <div class="clearfix"></div>
                <p>
                  Don't have an account?{" "}
                  <a href="/register" class="thembo">
                    {" "}
                    Register here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
