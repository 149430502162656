import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { BsFillPencilFill } from "react-icons/bs";
import { set } from "firebase/database";
import { toast } from "react-toastify";

const UpdateConfigMail = ({ id }) => {
  const [show, setShow] = useState(false);
  const [companyid, setCompanyid] = useState("");
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [port, setPort] = useState("");
  const [token, setToken] = useState("");
  //const [id, setId] = useState("");

  const baseURL = process.env.REACT_APP_BASE_URL;

  const updateShow = () => {
    axios
      .get(`${baseURL}/config/${id}`)
      .then(function (response) {
        const responseData = response.data.data; // Assuming data is nested
        setType(responseData.type);
        setEmail(responseData.email_id);
        // setPassword(responseData.e_password);
        setPort(responseData.port);
        setToken(responseData.token);
        setShow(true); // Show the modal after setting the state
      })
      .catch(function (error) {
        console.log("Unsuccessful:", error);
        alert("Invalid");
      });
  };

  function submitForm(e) {
    e.preventDefault();

    const configData = {
      type: type,
      email_id: email,
      e_password: password,
      port: port,
      token: token,
    };

    axios
      .put(`${baseURL}/updateconfig/${id}`, configData)
      .then(function (response) {
        if (response.data.success) {
          setType("");
          setEmail("");
          setPassword("");
          setPort("");
          setToken("");
          setShow(false);
          toast.success("Configuration updated Successfully");
          window.location.reload();
        } else {
          toast.error("Failed to update configuration");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Failed to update configuration");
      });
  }

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Button
        className="btn btn-primary btn-sm mx-1 rounded-circle"
        onClick={() => updateShow(id)}
      >
        <BsFillPencilFill />
      </Button>

      <Modal show={show} size="lg" centered style={{color: 'black'}}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Configuration
          </Modal.Title>
        </Modal.Header>

        <Form>
          <Modal.Body>
            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>AC Type</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="email"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Port</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={port}
                    onChange={(e) => {
                      setPort(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>

            <fieldset>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm={2}>
                  <Form.Label>Token</Form.Label>
                </Col>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={token}
                    onChange={(e) => {
                      setToken(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </fieldset>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" onClick={submitForm}>
              Send
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Exit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateConfigMail;
