import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Fpassword = () => {
  const [email, setEmail] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL;

  const triggerResetEmail = async () => {
    try {
      const response = await axios.post(`${baseURL}/forgot-password`, {
        email,
      });
      console.log("res", response.data.newEmail.body);

      if (response.status === 200) {
        console.log("Password reset email sent");
        console.log("succ", response.data.message);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Clear form fields
        setEmail("");
      }
    } catch (error) {
      console.error("Error sending reset email:", error);
      toast.error(error.response.data.error || "Error sending reset email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <section className="login-19">
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-2">
                            Forgot Your Password?
                          </h1>
                          <p className="mb-4">
                            We get it, stuff happens. Just enter your email
                            address below and we'll send you a link to reset
                            your password!
                          </p>
                        </div>

                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Email Address..."
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                        <button
                          className="btn btn-primary btn-lg btn-theme"
                          onClick={triggerResetEmail}
                        >
                          Reset Password
                        </button>
                        <hr />
                        <div className="forgot-password-reg float-left">
                          <a className="small" href="/forgot-password">
                            Forgot Password?
                          </a>
                        </div>
                        <div className="login-reg float-right pb-3">
                          <a className="small" href="/login">
                            Already have an account? Login!
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Fpassword;
