import "./App.css";
import Billing from "./Billing";
import Chat from "./Chat";
import Login from "./Login";
import Profile from "./Profile";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import Fpassword from "./Fpassword";
import Register from "./Register";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsAuthenticated,
  selectToken,
  setIsAuthenticated,
} from "./store/slices/authSlice";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { setUserId } from "./store/slices/userSlice";
import ResetPassword from "./pages/ResetPassword";
import APIDocs from "./pages/APIdoc";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const token = useSelector(selectToken);
  const location = useLocation();

  useEffect(() => {
    if (token) {
      // Decode the JWT token to access the payload
      const decodedToken = jwtDecode(token);

      // Extract the userId from the decoded token
      const userId = decodedToken.userId;

      // Update the isAuthenticated state in the Redux store
      dispatch(setUserId(userId));
      dispatch(setIsAuthenticated(true));
      console.log("id", userId);
    } else {
      // No authentication token found, set isAuthenticated to false
      dispatch(setIsAuthenticated(false));
    }
  }, [dispatch, location.search, token]);

  return (
    <div>
      <Routes>
        {/* <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<Fpassword />} /> */}
        {isAuthenticated ? (
          <>
            <Route path="/billing" element={<Billing />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/api-docs" element={<APIDocs />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to={"/login"} replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<Fpassword />} />

            <Route
              key={location.key}
              path="/reset-password"
              element={<ResetPassword />}
            />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
