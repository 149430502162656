import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  userInfo: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    // updateUser(state, action) {
    //   state.userInfo = { ...state.userInfo, ...action.payload };
    // },
  },
});

export const { setUserId, setUserInfo } = userSlice.actions;
export const selectUserId = (state) => state.user.userId;
export const selectUserInfo = (state) => state.user.userInfo;
export default userSlice.reducer;
