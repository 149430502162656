import React, { useEffect, useState } from "react";
import "./../Billing.css";
// import Checkout from "..//Checkout";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { db, auth, child, push } from "./firebase";
import { onValue, ref, set, update } from "firebase/database";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import {
  selectUserInfo,
  setUserId,
  setUserInfo,
} from "../store/slices/userSlice";
import { db } from "../firebase";
import { selectToken, setToken } from "../store/slices/authSlice";
const stripePromise = loadStripe(
  "pk_test_51LnMTTH7bYn4KIwLbgZZFQww66bsnJn8fZJMnCh8BNUs7VZWQ3Sj2IoPgOrSTEBBgT7yYKpahzAJyBIEljRdIMpX002iZTvKiC"
);

// This values are the props in the UI

const currency = "USD";
const style = { layout: "vertical" };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ currency, showSpinner, amount, uid }) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]);

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <ToastContainer />
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function () {
            let token = 0;
            if (parseInt(amount) === 10) {
              token = 300000;
            } else if (parseInt(amount) === 5) {
              token = 150000;
            } else if (parseInt(amount) === 2) {
              token = 60000;
            } else {
              token = 0;
            }

            toast.success(
              "Payment Success " + token + " Token added Successfully ",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            ); //alert('success');
            // Your code here after capture the order
            console.log(uid);

            update(ref(db, "users/" + uid + "/payment_history"), {
              amount: amount,
              data: data,
              purchased: token,
            });

            setTimeout(() => {
              window.location.reload(false);
            }, 5000);

            //  db.child("users/"+uid).child("payment").child("amount").setValue(amount)
          });
        }}
      />
    </>
  );
};

const APIDocs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);

  const userInfo = useSelector(selectUserInfo);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [paymethod, setPaymethod] = useState("paypal");
  const [pay, setPay] = useState(10);

  const [projects, setProjects] = useState([]);

  const [plans, setPlans] = useState([]);
  const [uid, setUid] = useState("");

  const [clientSecret, setClientSecret] = useState("");
  var itemstofilter = [];

  const [requestMailCount, setRequestMailCount] = useState(0);

  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    axios
      .get(`${baseURL}/mail/count`)
      .then((res) => {
        setRequestMailCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // create a payment intent
  useEffect(() => {
    setProjects([]);

    const query = ref(db, "users");
    onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        Object.values(data).map((project) => {
          // console.log(project);
          if (project.email === email) {
            setProjects((projects) => [...projects, project]);
            // setUid(project.uid);
          }
        });
      }
    });

    const queryplan = ref(db, "plan");
    onValue(queryplan, (snapshot) => {
      const dataplan = snapshot.val();

      if (snapshot.exists()) {
        Object.values(dataplan).map((plan) => {
          // console.log(project);
          //if(project.email===email){
          setPlans((plans) => [...plans, plan]);
          // }
        });
      }
    });

    //   fetch("http://localhost:3001/create-payment-intent", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ price: totalPrice }),
    //   })
    //     .then((res) => {
    //       return res.json();
    //     })
    //     .then((data) => {
    //       setClientSecret(data.clientSecret);
    //     });
  }, [email]);

  const creditUsedn = (projects) => {
    let total = 0;
    projects &&
      Object.keys(projects).map((item, i) => {
        projects &&
          projects[item].searches &&
          Object.keys(projects[item]?.searches).map((search, j) => {
            total = total + parseInt(projects[item].searches[search].credit);
          });
      });
    return total;
  };

  const totalsearch = (projects) => {
    let total = 0;
    projects &&
      Object.keys(projects).map((item, i) => {
        projects &&
          projects[item].searches &&
          Object.keys(projects[item]?.searches).map((search, j) => {
            total = total + (i + 1);
          });
      });
    return total;
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(
      "http://localhost:85/chatbudy/create.php",
      { mode: "cors" },
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
      }
    )
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const logout = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/logout`);
      if (response.status === 200) {
        localStorage.removeItem("token");
        dispatch(setToken(null));
        navigate("/");
      }
    } catch (error) {
      console.error("Logout error:", error);
      // Handle logout error (e.g., show error message to user)
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId;
        dispatch(setUserId(userId));
        // localStorage.setItem("userId", JSON.stringify(userId)); // Store userId in localStorage
        try {
          const response = await axios.get(`${baseURL}/user/${userId}`);
          const userData = response.data.user;
          dispatch(setUserInfo(userData));
          //   localStorage.setItem("user", JSON.stringify(userData)); // Store userData in localStorage
          //   console.log("user data", userData);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        navigate("/api-docs");
      }
    };

    fetchUserData();
  }, [baseURL, dispatch, navigate, token]);
  useEffect(() => {
    // let user = localStorage.getItem("user");
    // console.log("user", user);
    if (userInfo) {
      //   let modifieduser = JSON.parse(user);
      setEmail(userInfo.email);
      setName(userInfo.name);
      setUid(userInfo.id);
    } else {
      navigate("/");
    }
  }, [navigate, userInfo]);

  return (
    <>
      <div id="wrapper">
        <ul
          class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          <a
            class="sidebar-brand d-flex align-items-center justify-content-center"
            href="/billing"
          >
            <div class="sidebar-brand-icon rotate-n-15">
              <i class="fas fa-laugh-wink"></i>
            </div>
            <div class="sidebar-brand-text mx-3">Chat buddy</div>
          </a>

          <hr class="sidebar-divider my-0" />

          <li class="nav-item active">
            <a class="nav-link" href="/billing">
              <i class="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>
          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/chat">
              <i class="fas fa-fw  far fa-comment"></i>
              <span>Configuration</span>
            </a>
          </li>
          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/profile">
              <i class="fas fa-fw far fa-user"></i>
              <span>Profile</span>
            </a>
          </li>
          <hr class="sidebar-divider my-0" />
          <li class="nav-item active">
            <a class="nav-link" href="/api-docs">
              <span>API Doc</span>
            </a>
          </li>

          <hr class="sidebar-divider d-none d-md-block" />

          {/*           
            <div class="text-center d-none d-md-inline">
                <button class="rounded-circle border-0" id="sidebarToggle"></button>
            </div> */}

          <lottie-player
            src="https://assets7.lottiefiles.com/packages/lf20_jh9gfdye.json"
            background="transparent"
            speed="1"
            style={{ width: "100%", height: "300px" }}
            loop
            autoplay
          ></lottie-player>
        </ul>

        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              <button
                id="sidebarToggleTop"
                class="btn btn-link d-md-none rounded-circle mr-3"
              >
                <i class="fa fa-bars"></i>
              </button>

              <form class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button">
                      <i class="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown no-arrow d-sm-none">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="searchDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-search fa-fw"></i>
                  </a>
                </li>

                <div class="topbar-divider d-none d-sm-block"></div>

                {/* <!-- Nav Item - User Information --> */}
                <li class="nav-item dropdown no-arrow">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="mr-2 d-none d-lg-inline text-gray-600 small">
                      {email}
                    </span>
                    <img
                      class="img-profile rounded-circle"
                      src="img/undraw_profile.svg"
                    />
                  </a>
                  {/* <!-- Dropdown - User Information --> */}
                  <div
                    class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                  >
                    <a class="dropdown-item" href="/profile">
                      <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profile
                    </a>

                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" onClick={logout}>
                      <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            {/* <!-- End of Topbar -->

                <!-- Begin Page Content --> */}
            <div class="container-fluid">
              {/* <!-- Page Heading --> */}
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">API Docs</h1>
              </div>

              {/* <!-- Content Row --> */}

              {/* <!-- Content Row --> */}
            </div>
            {/* <!-- /.container-fluid --> */}
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <footer class="sticky-footer bg-white">
            <div class="container my-auto">
              <div class="copyright text-center my-auto">
                <span>Copyright &copy; Your Website 2021</span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
    </>
  );
};

export default APIDocs;
